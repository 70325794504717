import { removeFromLocalStorage, setLocalStorageValue, getLocalStorageValue } from '@/application/utils/localstorage'

export const form = {
  state: {},
  getters: {
    getSingleFormCache: (state) => (license) => {
      return state[license.license_key] && state[license.license_key].SINGLE ? { ...state[license.license_key].SINGLE } : null
    },
    getBulkFormCache: (state) => (license) => {
      return state[license.license_key] && state[license.license_key].BULK ? { ...state[license.license_key].BULK } : null
    },
    getLookupFormCache: (state) => (license) => {
      return state[license.license_key] && state[license.license_key].LOOKUP ? { ...state[license.license_key].LOOKUP } : null
    },
  },
  actions: {
    getSavedFormsData: ({ rootState, commit }) => {
      const license = rootState.licenses.license
      commit('rememberFormData', {
        license: license,
        type: 'SINGLE',
        data: getLocalStorageValue(license.license_key + ':' + 'SINGLE'),
      })

      commit('rememberFormData', {
        license: license,
        type: 'BULK',
        data: getLocalStorageValue(license.license_key + ':' + 'BULK'),
      })
    },
    saveSingleFormData: ({ commit }, { license, data }) => {
      commit('rememberFormData', {
        license,
        data,
        type: 'SINGLE',
      })
    },
    saveBulkFormData: ({ commit }, { license, data }) => {
      commit('rememberFormData', {
        license,
        data,
        type: 'BULK',
      })
    },
    saveLookupFormData: ({ commit }, { license, data }) => {
      commit('rememberFormData', {
        license,
        data,
        type: 'LOOKUP',
      })
    },
    clearFormData({ commit, rootState }, { type }) {
      const license = rootState.licenses.license
      commit('clearFormData', { license, type })
    },
    clearAllFormsData({ commit, rootState }) {
      const license = rootState.licenses.license
      commit('clearFormData', { license, type: 'SINGLE' })
      commit('clearFormData', { license, type: 'BULK' })
      commit('clearFormData', { license, type: 'LOOKUP' })
    },
  },
  mutations: {
    rememberFormData(state, { license, type, data }) {
      setLocalStorageValue(license.license_key + ':' + type, data)
      if (state[license.license_key] === undefined) {
        state[license.license_key] = {}
      }
      state[license.license_key][type] = { ...data }
    },
    clearFormData(state, { license, type }) {
      if (license) {
        if (state[license.license_key] && state[license.license_key][type]) {
          if (state[license.license_key] === undefined) {
            state[license.license_key] = {}
          }
          state[license.license_key][type] = null
          removeFromLocalStorage(license.license_key + ':' + type)
        }
      }
    },
  },
}
