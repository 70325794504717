import { createI18n } from 'vue-i18n/index'
import messages from './messages.json'

// export const AVAILABLE_LANGUAGES = ['en', 'ru', 'ua']
export const AVAILABLE_LANGUAGES = ['en', 'ua']
export const DEFAULT_LANGUAGE = 'en'

let lang = localStorage.getItem('lang')
if (lang === null) {
  lang = DEFAULT_LANGUAGE
  localStorage.setItem('lang', lang)
  localStorage.setItem('default_language', 'Y')
}

export const i18n = createI18n({
  locale: lang,
  globalInjection: true,
  messages,
  pluralizationRules: {
    ru: function(choice, choicesLength) {
      if (choice % 10 === 1 && choice % 100 !== 11) {
        return 0
      } else if (choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)) {
        return 1
      } else {
        return 2
      }
    },
    ua: function(choice, choicesLength) {
      if (choice % 10 === 1 && choice % 100 !== 11) {
        return 0
      } else if (choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)) {
        return 1
      } else {
        return 2
      }
    },
  },
})

export { lang }
