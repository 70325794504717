import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'
import { i18n } from '@/application/localization'

const domain = process.env.VUE_APP_API_PATH
const checkoutSenderApi = `${domain}api/v1/checkout/sender-name/`
const currentSenderNamesApi = `${domain}api/v1/dashboard/sender-names/`
const getDeleteSenderNameApi = (id) => `${domain}api/v1/dashboard/request/sender-name/${id}/`
const getUpdateSenderAliasApi = (id) => `${domain}api/v1/dashboard/request/sender-name/alias/${id}/`
const newSenderNamesApi = (planId) => `${domain}api/v1/dashboard/request/sender-name/new/?plan_id=${planId}`
const updateSenderNameWebhookApi = (id) => `${domain}api/v1/dashboard/request/sender-name/webhook/${id}/`
const { t } = i18n.global

export const checkSenderName = (name, domain) => {
  return interceptor(axios).post(checkoutSenderApi, { name, domain })
    .then((response) => {
      const { code } = response.data.status
      return response.data.success && response.data.status === 'available' ? true : t('forms.checkout.errors.' + code)
    }).catch(({ response }) => {
      const { code } = response.data
      return t('forms.checkout.errors.' + code)
    })
}

export const checkSenderNameSync = (name) => {
  return /^[a-z0-9-_.+]+$/.test(name)
}

export const checkSenderNameMininal = (name) => {
  return /^[a-z0-9-_.+]+$/.test(name.toLowerCase())
}

export const getCurrentSenderNames = () => interceptor(axios).get(currentSenderNamesApi)
export const getSenderNameCheckout = (planId) => {
  return interceptor(axios).get(newSenderNamesApi(planId))
}
export const storeSenderNameCheckout = (data, planId) => interceptor(axios).put(newSenderNamesApi(planId), data)
export const finishSenderNameCheckout = (data, planId) => interceptor(axios).post(newSenderNamesApi(planId), data)
export const deleteSenderName = (id) => interceptor(axios).delete(getDeleteSenderNameApi(id))

export const deleteSenderAlias = (id) => interceptor(axios).delete(getUpdateSenderAliasApi(id))
export const createSenderAlias = (data, id) => interceptor(axios).post(getUpdateSenderAliasApi(id), data)
export const updateSenderNameWebhook = (id, data) => interceptor(axios).post(updateSenderNameWebhookApi(id), data)
