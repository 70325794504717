import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

export const includeTrackingScripts = () => {
  if (process.env.VUE_APP_TRACKING === 'Y') {
    includeHubspot()
    includeFirebaseLogging()
  }
}

export const trackPurchase = (eventData) => {
  const { currency, price } = parseEventData(eventData)

  logFbPixel('Purchase', { currency: currency, value: price })
  logFirebase('purchase', {
    transaction_id: eventData.checkout.id,
    ...getPurchaseObjectFromEventData(eventData),
  })
}

export const trackCart = (eventData) => {
  const { currency, price } = parseEventData(eventData)
  logFbPixel('AddToCart', { currency: currency, value: price })
  logFirebase('begin_checkout', getPurchaseObjectFromEventData(eventData))
}

export const trackAddPaymentInfo = (eventData) => {
  if (!eventData.checkout.prices) {
    return
  }
  const { currency } = parseEventData(eventData)
  logFbPixel('AddPaymentInfo', { currency: currency })
  logFirebase('add_payment_info', getAddPaymentMethodObjectFromEventData(eventData))
}

export const trackRegistrationComplete = (data) => {
  initFbPixel({
    em: data.email,
    fn: data.first_name,
    ln: data.last_name,
    ph: data.phone_number ? data.phone_number.replace(/\D+/g, '') : '',
    zp: data.postal_code,
    country: data.country,
  })
  logFbPixel('CompleteRegistration', { status: true, content_name: 'imessage_api' })
  logFirebase('sign_up', { method: 'dashboard' })
}

export const trackCommittedDiscountSubmit = () => {
  logFbPixel('Lead', { content_category: 'committed_discount_form' })
  logFirebase('generate_lead')
}

export const trackLogin = () => {
  logFirebase('login', { method: 'dashboard' })
}

export const includeHubspot = () => {
  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('src', '//js-eu1.hs-scripts.com/25055244.js')
  scriptElement.setAttribute('async', true)
  scriptElement.setAttribute('defer', true)
  scriptElement.setAttribute('id', 'hs-script-loader')
  scriptElement.setAttribute('type', 'text/javascript')
  document.body.appendChild(scriptElement)
}

const includeFirebaseLogging = () => {
  window.firebaseAppInstance = initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_APP_CONFIG))
  window.firebaseAnalyticsInstance = getAnalytics(window.firebaseAppInstance)
}

const logFirebase = (event, data) => {
  if (process.env.VUE_APP_TRACKING === 'Y' && window.firebaseAnalyticsInstance) {
    logEvent(window.firebaseAnalyticsInstance, event, data)
  }
}

const logFbPixel = (event, data) => {
  if (process.env.VUE_APP_TRACKING === 'Y' && window.fbq) {
    window.fbq('track', event, data)
  }
}

const initFbPixel = (data) => {
  if (process.env.VUE_APP_TRACKING === 'Y' && window.fbq) {
    window.fbq('init', process.env.VUE_APP_PIXEL_ID, data)
  }
}

const parseEventData = (eventData) => {
  const totalPrice = +eventData.checkout.prices.customer.total
  const tax = +eventData.checkout.prices.customer.total_tax
  const price = totalPrice - tax
  const currency = eventData.checkout.prices.customer.currency
  return { totalPrice, tax, price, currency }
}

const getPurchaseObjectFromEventData = (eventData) => {
  const { currency, price, tax, totalPrice } = parseEventData(eventData)

  return {
    currency: currency,
    value: price,
    revenue: totalPrice,
    tax: tax,
  }
}

const getAddPaymentMethodObjectFromEventData = (eventData) => {
  const { currency } = parseEventData(eventData)

  return { currency: currency }
}
