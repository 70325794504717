import axios from 'axios'

const url = 'https://open.kickbox.com/v1/disposable/'
export const validateIsDisposable = (email) => {
  if (!email) {
    return Promise.resolve(true)
  }
  const parts = email.split('@')
  return parts.length === 2 ? axios.get(url + '@' + parts[1])
    .then(res => {
      return !res.data.disposable
    }).catch(() => {
      return true
    }) : Promise.resolve(false)
}
