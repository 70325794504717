import { createStore } from 'vuex'
import { auth } from './modules/auth.js'
import { licenses } from './modules/licenses.js'
import { form } from '@/application/store/modules/form'
import { menu } from '@/application/store/modules/menu'
import { ui } from '@/application/store/modules/ui'

export const store = createStore({
  modules: {
    auth,
    licenses,
    form,
    menu,
    ui,
  },
})
