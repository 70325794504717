import { useLogout } from '@/application/composables/logout.js'

export function interceptor(axios) {
  const instance = axios.create()
  const { logout } = useLogout()
  instance.interceptors.response.use(
    res => res,
    // intercept every 403 to logout user
    error => {
      if (error.response.status === 403) {
        logout()
      }
      return Promise.reject(error)
    }
  )
  return instance
}
