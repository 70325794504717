import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH
const licensesAPI = `${domain}api/v1/dashboard/`
const secretKeyResetAPI = `${domain}api/v1/dashboard/reset-token/`
const updateLicenseFieldAPI = `${domain}api/v1/dashboard/`
const sandboxUsersAPI = `${domain}api/v1/dashboard/sandbox-users/`
const resetSupportAPI = `${domain}api/v1/dashboard/reset-support-pin/`

export const getLicense = () => interceptor(axios).get(licensesAPI)
export const resetSecretKey = (data) => interceptor(axios).post(secretKeyResetAPI, data)
export const updateLicenseField = (data) => interceptor(axios).post(`${updateLicenseFieldAPI}`, data)
export const getSandboxUsers = () => interceptor(axios).get(sandboxUsersAPI)
export const addSandboxUser = (data, headers) => interceptor(axios).post(sandboxUsersAPI, data, { headers })
export const deleteSandboxUser = (data, headers) => interceptor(axios).delete(sandboxUsersAPI, { data, headers })
export const resetSupportPin = () => interceptor(axios).post(resetSupportAPI)
