export const menu = {
  state: {
    manuallyActiveSection: '',
  },
  getters: {
    getActiveSection: (state) => state.manuallyActiveSection,
  },
  mutations: {
    openSection: (state, section) => {
      state.manuallyActiveSection = state.manuallyActiveSection === section ? '' : section
    },
    closeSection: (state) => {
      state.manuallyActiveSection = ''
    },
  },
  actions: {
    toggleSection({ commit }, section) {
      commit('openSection', section)
    },
    closeManuallyOpenedSection({ commit }) {
      commit('closeSection')
    },
  },
}
