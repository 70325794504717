import { useStore } from 'vuex'

export function useLogout() {
  const store = useStore()
  const logout = () => {
    store.dispatch('clearAllFormsData')
    store.commit('logout')
  }
  return {
    logout,
  }
}
