import { getLicense, resetSupportPin } from '@/application/services/dashboard.js'

export const licenses = {
  state: {
    license: null,
    supportPin: null,
    dashboardReload: false,
  },
  getters: {
    getLicense: state => state.license,
    getSupportPin: state => state.supportPin,
    isDashboardReload: state => state.dashboardReload,
  },
  actions: {
    switchOnDashboardReload({ commit }) {
      commit('switchDashboardReload', true)
    },
    getLicense({ commit }) {
      return getLicense()
        .then(({ data }) => {
          commit('setLicense', data)
          commit('setSupportPin', data.support_pin)
          commit('switchDashboardReload', false)
          return true
        })
        .catch(err => {
          console.error(err.response)
          return false
        })
    },
    resetSupportPin({ commit }) {
      return resetSupportPin()
        .then(({ data }) => {
          commit('setSupportPin', data.support_pin)
          return true
        }).catch(err => {
          console.error(err.response)
          return false
        })
    },
  },
  mutations: {
    switchDashboardReload(state, flag) {
      state.dashboardReload = flag
    },
    setLicense(state, payload) {
      state.license = payload
    },
    setSupportPin(state, payload) {
      state.supportPin = payload
    },
    changeLicenseApiProperty(state, { key, value, license_key }) {
      state.license[key] = value
    },
    addAuthSandboxContact(state, { license_key, contact }) {
      if (!state.license.auth_sandbox_recipients) {
        state.license.auth_sandbox_recipients = []
      }
      state.license.auth_sandbox_recipients.push(contact)
    },
    deleteAuthSandboxContact(state, { contact }) {
      if (state.license.auth_sandbox_recipients.includes(contact)) {
        const index = state.license.auth_sandbox_recipients.indexOf(contact)
        state.license.auth_sandbox_recipients.splice(index, 1)
      }
    },
    addSandboxContact(state, { license_key, contact }) {
      if (state.license.sandbox_recipients) {
        state.license.sandbox_recipients.push(contact)
      } else {
        state.license.sandbox_recipients = []
        state.license.sandbox_recipients.push(contact)
      }
    },
    deleteSandboxContact(state, { contact }) {
      if (state.license.sandbox_recipients.includes(contact)) {
        const index = state.license.sandbox_recipients.indexOf(contact)
        state.license.sandbox_recipients.splice(index, 1)
      }
    },
    changeAllowOverLimitState(state, { data }) {
      state.license.allow_over_limit = data.allow_over_limit
    },
    changeWebhooksState(state, { data }) {
      Object.keys(state.license.webhook).forEach(key => {
        Object.keys(data).forEach(dataKey => {
          if (key === dataKey) {
            state.license.webhook[key] = data[dataKey]
          }
        })
      })
    },
    changeWebhookAlertsState(state, { data }) {
      Object.keys(state.license.webhook.preferences).forEach(key => {
        Object.keys(data).forEach(dataKey => {
          if (key === dataKey) {
            state.license.webhook.preferences[key] = data[dataKey]
          }
        })
      })
    },
    changeEmailPreferencesState(state, { data }) {
      Object.keys(state.license.email_preferences).forEach(key => {
        Object.keys(data).forEach(dataKey => {
          if (key === dataKey) {
            state.license.email_preferences[key] = data[dataKey]
          }
        })
      })
    },
  },
}
