export const getLocalStorageValue = (key) => {
  const item = JSON.parse(localStorage.getItem(key))
  if (item) {
    const { value, expiration } = item
    if (!expiration || Date.now() < expiration) {
      return value
    }
    localStorage.removeItem(key)
  }
  return null
}

export const setLocalStorageValue = (key, value, ttlSeconds = 3600 * 48) => {
  localStorage.setItem(key, JSON.stringify({
    value: value,
    expiration: ttlSeconds > 0 ? Date.now() + ttlSeconds * 1000 : null,
  }))
}

export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key)
}
