import { removeFromLocalStorage } from '@/application/utils/localstorage'

export const ui = {
  state: {
    openVerification: false,
    verified: false,
  },
  getters: {
    getVerificationOpened: state => state.openVerification,
    getVerified: state => state.verified,
  },
  actions: {
    openVerification({ commit }) {
      removeFromLocalStorage('verify-modal-closed')
      commit('openVerification', true)
    },
    closeVerification({ commit }) {
      commit('openVerification', false)
    },
    markPhoneAsVerified({ commit }) {
      commit('markVerified', true)
    },
  },
  mutations: {
    openVerification(state, payload) {
      state.openVerification = payload
    },
    markVerified(state, payload) {
      state.verified = payload
    },
  },
}
