import { validatePhoneEmail, inValidatePhoneEmail } from '@/application/utils/validation'

export function useExtractRecipients(limit) {
  const extractRecipients = (recipients) => {
    const filteredRecipients = recipients.split(',')
      .reduce((res, current) => {
        res = res.concat(current.split('\n').map(i => i.trim()))
        return res
      }, [])
      .filter(recipient => recipient.length > 0)
    if (limit !== undefined) {
      return filteredRecipients.slice(0, limit)
    }
    return filteredRecipients
  }

  const extractValidRecipients = (recipients) => extractRecipients(recipients).filter(validatePhoneEmail)
  const extractInValidRecipients = (recipients) => extractRecipients(recipients).filter(inValidatePhoneEmail)

  return {
    extractRecipients,
    extractValidRecipients,
    extractInValidRecipients,
  }
}
