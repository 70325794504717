import axios from 'axios'
import { router } from '@/application/router/index.js'
import { extractParamsFromUrl } from '@/application/utils/tokens'

export const auth = {
  state: {
    token: '',
  },
  getters: {
    getLoggedIn: state => !!state.token,
    getToken: state => state.token,
  },
  mutations: {
    changeAuthStatus(state, payload) {
      state.token = payload
    },
    logout(state) {
      document.cookie = 'session_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      state.token = ''
      delete axios.defaults.headers.common.Authorization
      const { email } = extractParamsFromUrl()

      if (window.location.pathname !== '/sign-up' && !window.location.pathname.startsWith('/landing-invite')) {
        router.push({
          name: 'sign-in',
          query: { email: email },
        })
      }
    },
  },
}
