import { routes } from './routes.js'
import { createRouter, createWebHistory } from 'vue-router'
import { store } from '../store/index.js'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  },
})
router.beforeEach((to, from, next) => {
  if (to.meta.requiresSandbox === true) {
    const license = store.getters.getLicense(to.params.id)
    const sandboxOnly = license
    if (sandboxOnly) {
      next()
    }
    next('/403')
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.getLoggedIn) {
      next()
      return
    }
    next('/sign-in')
  } else if (to.name === 'sign-in' && store.getters.getLoggedIn) {
    // prevent sign-in route if user is authenticated
    next('/dashboard')
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  store.dispatch('closeManuallyOpenedSection')
})

export { router }
