import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max'
import { i18n } from '@/application/localization'

const { t } = i18n.global
export const validatePhoneEmailTrimmed = (value) => validatePhoneEmail(value.trim())
export const validatePhoneEmail = (value) => validateEmail(value) || validatePhone(value)
export const inValidatePhoneEmail = (value) => !validatePhoneEmail(value)
export const getTrimmedLength = (value) => {
  const trimmed = value.replace(/\s/g, '')
  return trimmed.length
}

export const validatePhone = (phone) => {
  const phoneNumber = extractPhone(phone)
  return phoneNumber ? isValidPhoneNumber(phoneNumber.number) : false
}
const validateEmail = (email) => /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,20}$/.test(email)

export const validateMobilePhone = (phone) => {
  if (!phone) {
    return true
  }
  const effectivePhone = phone.indexOf('+') !== 0 ? '+' + phone : phone
  const phoneNumber = extractPhone(effectivePhone)
  if (!phoneNumber || !isValidPhoneNumber(effectivePhone)) {
    return false
  }
  return !['MOBILE', 'FIXED_LINE_OR_MOBILE'].includes(phoneNumber.getType()) ? t('forms.checkout.errors.160') : true
}

const extractPhone = (phone) => {
  const effectivePhone = phone.indexOf('+') !== 0 ? '+' + phone : phone
  try {
    return parsePhoneNumber(effectivePhone)
  } catch (e) {
    return null
  }
}
