import { getObjectValueByKey } from '@/application/utils/object'

export const extractTokensFromUrl = () => {
  const params = extractParamsFromUrl()
  return {
    token: getObjectValueByKey(params, 'token'),
    secret: getObjectValueByKey(params, 'secret'),
    license: getObjectValueByKey(params, 'license'),
  }
}

export const extractParamsFromUrl = () => {
  const url = new URL(window.location)
  const paramsString = url.search.replace('?', '')
  const keyValueArray = paramsString.split('&')
  const paramsObj = {}
  keyValueArray.forEach(item => {
    const [key, value] = item.split('=')
    paramsObj[key] = value
  })

  return paramsObj
}
