import { defineRule, configure } from 'vee-validate'
import { required, email, min, max, alpha_num, max_value, min_value } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import { lang } from '@/application/localization/index.js'
import ru from '@vee-validate/i18n/dist/locale/ru.json'
import uk from '@vee-validate/i18n/dist/locale/uk.json'
import { useExtractRecipients } from '@/application/composables/extractRecipients.js'
import { time } from '@/application/utils/time'
import {
  validatePhoneEmailTrimmed,
  validatePhoneEmail,
  getTrimmedLength,
  validateMobilePhone,
  validatePhone
} from '@/application/utils/validation'
import { checkSenderName, checkSenderNameSync, checkSenderNameMininal } from '@/application/services/sender-name'
import { validateIsDisposable } from '@/application/services/email-validation'

defineRule('required', required)
defineRule('email', email)
defineRule('disposable_email', validateIsDisposable)
defineRule('min', min)
defineRule('max', max)
defineRule('min_value', min_value)
defineRule('max_value', max_value)
defineRule('alpha_num', alpha_num)
defineRule('phone_email', validatePhoneEmail)
defineRule('phone_mobile', validateMobilePhone)
defineRule('phone', validatePhone)

defineRule('min_trimmed', (value, length) => {
  return getTrimmedLength(value) >= length
})

defineRule('phone_email_list', value => {
  const { extractRecipients } = useExtractRecipients()
  const recipients = extractRecipients(value)

  return recipients.every(validatePhoneEmailTrimmed)
})
defineRule('uppercase', value => {
  const uppercaseRegexp = /^[A-Z0-9]*$/
  if (!uppercaseRegexp.test(value)) {
    return false
  }
  return true
})
defineRule('url_or_deeplink', value => {
  const urlDeepLinkRegexp = /([a-zA-Z]{2,20}):\/\/([\w_-]+(?:(?:\.[\w_-]+)?))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/igm
  if (!urlDeepLinkRegexp.test(value)) {
    if (value === undefined || value === '') {
      return true
    }
    return false
  }
  return true
})
defineRule('url', (value, [requireProtocol]) => {
  let urlRegexp = /^$|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)(\/)?$/
  if (requireProtocol && requireProtocol === 'false') {
    urlRegexp = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)(\/)?$/
  }
  if (!urlRegexp.test(value)) {
    if (value === undefined || value === '') {
      return true
    }
    return false
  }
  return true
})
defineRule('media_url', value => {
  const urlRegexp = /(https?:\/\/)([^\s(["<,>/]*)(\/)[^\s[",><]*(.\.gif|.\.png|.\.jpe?g)(\?[^\s[",><]*)?/
  if (!urlRegexp.test(value)) {
    if (value === undefined || value === '') {
      return true
    }
    return false
  }
  return true
})
defineRule('time_to_after_from', (value, [bulk_from_time, bulk_to_time]) => {
  return time.indexOf(bulk_to_time) > time.indexOf(bulk_from_time)
})
defineRule('bulk_days', (value) => {
  return value.length > 0
})
defineRule('sender_name', (value, [name, domain]) => {
  return name && domain ? checkSenderName(name, domain) : false
})
defineRule('sender_name_frontend_minimal', checkSenderNameMininal)
defineRule('sender_name_frontend', checkSenderNameSync)
defineRule('no_spaces', (value) => typeof value === 'string' && value.indexOf(' ') === -1)

configure({
  validateOnInput: true,
  generateMessage: localize({
    en: {
      messages: {
        required: 'This field is required',
        phone: 'Enter a valid phone',
        phone_email: 'Enter a valid phone or email',
        phone_email_list: 'Invalid recipients',
        uppercase: 'This field must be in uppercase',
        url: 'Enter a valid URL address',
        url_or_deeplink: 'Enter a valid URL address or deep link',
        media_url: 'Enter a valid URL address',
        time_to_after_from: 'To time field value must be later than From time',
        min_trimmed: (meta) => {
          return `Minimal length should be at least ${meta.rule.params[0]}. Current length is ${getTrimmedLength(meta.value)}.`
        },
        max: (meta) => {
          return `Maximum length shouldn't be more than ${meta.rule.params[0]}. Current length is ${meta.value.length}.`
        },
        min: (meta) => {
          return `Minimum length shouldn't be less than ${meta.rule.params[0]}. Current length is ${meta.value.length}.`
        },
        max_value: (meta) => {
          return `Maximum value shouldn't be more then ${meta.rule.params[0]}`
        },
        min_value: (meta) => {
          return `Minimum value shouldn't be less then ${meta.rule.params[0]}`
        },
        sender_name: '{field}  is unavailable',
        disposable_email: '{field} mustn\'t be disposable',
        phone_mobile: '{field} is not valid',
        sender_name_frontend: '{field} is not valid',
        sender_name_frontend_minimal: '{field} is not valid',
        no_spaces: 'Can not contain spaces',
      },
      names: {
        contant: 'Contact',
        companyName: 'Company name',
        contactUrl: 'Contact form url',
        otp: 'Code',
        text: 'Text',
        recipient: 'Recipient',
        bulk_recipients: 'Recipients',
        bulk_text: 'Text',
        bulk_from_date: 'Start date',
        bulk_from_time: 'From',
        bulk_to_time: 'To',
        bulk_time_zone: 'Timezone',
        bulk_sending_days: 'Send these days',
        bulk_agreement: 'Agreement',
        lookup_agreement: 'Agreement',
        agreement: 'Agreement',
        description: 'Description',
        senderName: 'Sender name',
        senderDomain: 'Sender domain',
        email: 'Email',
        name: 'Name',
        lastName: 'Last name',
        phoneNumber: 'Phone number',
        postalCode: 'Zip code',
        address: 'Address',
        city: 'City',
        country: 'Country',
        type: 'Type',
        value: 'Value',
        verificationCode: 'Verification code',
        confirm_password: 'Confirmation password',
      },
    },
    ru: {
      messages: {
        ...ru.messages,
        // required: '{field} обязательное поле',
        phone: 'Введите корректный номер телефона',
        phone_email: 'Введите корректный номер телефона или email',
        phone_email_list: 'Некорректные контакты получателей: ',
        uppercase: 'Поле {field} должно быть в верхнем регистре',
        url: 'Введите валидный URL адрес',
        url_or_deeplink: 'Enter a valid URL address or deep link',
        media_url: 'Введите валидный URL адрес',
        time_to_after_from: 'Время "До" должно быть больше времени "С"',
        min_trimmed: (meta) => {
          return `Минимальная длина поля должна составлять не менее ${meta.rule.params[0]} символов не включая пробелы. Текущее значение - ${getTrimmedLength(meta.value)}.`
        },
        max: (meta) => {
          return `Максимальная длина поля не должна быть больше ${meta.rule.params[0]}. Текущая длина - ${meta.value.length}.`
        },
        min: (meta) => {
          return `Minimum length shouldn't be less than ${meta.rule.params[0]}. Current length is ${meta.value.length}.`
        },
        max_value: (meta) => {
          return `Maximum value shouldn't be more then ${meta.rule.params[0]}`
        },
        min_value: (meta) => {
          return `Minimum value shouldn't be less then ${meta.rule.params[0]}`
        },
        sender_name: '{field} не доступно',
        disposable_email: '{field} не должен быть одноразовым',
        phone_mobile: 'Не является корректным телефонным номером',
        sender_name_frontend: 'Поле {field} заполнено некорректно',
        sender_name_frontend_minimal: 'Поле {field} заполнено некорректно',
        no_spaces: 'Не может содержать пробелы',
      },
      names: {
        contact: 'Контакт',
        companyName: 'Название компании',
        contactUrl: 'Адрес контактной формы',
        otp: 'Код',
        text: 'Текст',
        recipient: 'Получатель',
        bulk_recipients: 'Получатели',
        bulk_text: 'Текст',
        bulk_from_date: 'Дата начала',
        bulk_from_time: 'Время с',
        bulk_to_time: 'Время до',
        bulk_time_zone: 'Часовой пояс',
        bulk_sending_days: 'Отправить в эти дни',
        bulk_agreement: 'Соглашение',
        agreement: 'Соглашение',
        description: 'Описание',
        senderName: 'Имя отправителя',
        senderDomain: 'Домен отправителя',
        email: 'Email',
        name: 'Имя',
        lastName: 'Фамилия',
        phoneNumber: 'Телефонный номер',
        postalCode: 'Почтовый индекс',
        address: 'Адрес',
        city: 'Город',
        country: 'Страна',
        type: 'Тип',
        value: 'Значение',
        verificationCode: 'Проверочный код',
      },
    },
    ua: {
      messages: {
        ...uk.messages,
        // required: 'Повинно мати значення',
        phone: 'Введіть правильний номер телефону',
        phone_email: 'Введіть правильний номер телефону або email',
        phone_email_list: 'Некоректні контакти одержувачів: ',
        uppercase: 'Поле {field} має бути у верхньому регістрі',
        url: 'Введіть валідну URL-адресу',
        url_or_deeplink: 'Enter a valid URL address or deep link',
        media_url: 'Введіть валідну URL-адресу',
        time_to_after_from: 'Час "До" має бути більше часу "З"',
        min_trimmed: (meta) => {
          return `Мінімальна довжина поля повинна становити не менше ${meta.rule.params[0]} символів, не включаючи пробіли. поточне значення - ${getTrimmedLength(meta.value)}.`
        },
        max: (meta) => {
          return `Максимальна довжина поля не має бути більше ${meta.rule.params[0]}. Поточна довжина - ${meta.value.length}.`
        },
        min: (meta) => {
          return `Minimum length shouldn't be less than ${meta.rule.params[0]}. Current length is ${meta.value.length}.`
        },
        max_value: (meta) => {
          return `Maximum value shouldn't be more then ${meta.rule.params[0]}`
        },
        min_value: (meta) => {
          return `Minimum value shouldn't be less then ${meta.rule.params[0]}`
        },
        sender_name: '{field} недоступно',
        disposable_email: '{field} не має бути одноразовим',
        phone_mobile: 'Не є коректним номером',
        sender_name_frontend: 'Поле {field} заповнено неправильно',
        sender_name_frontend_minimal: 'Поле {field} заповнено неправильно',
        no_spaces: 'Не може містити пробіли',
      },
      names: {
        contact: 'Контакт',
        companyName: 'Назва компанії',
        contactUrl: 'URL контактної форми',
        otp: 'Код',
        text: 'Текст',
        recipient: 'Одержувач',
        bulk_recipients: 'Одержувачі',
        bulk_text: 'Текст',
        bulk_from_date: 'Дата початку',
        bulk_from_time: 'Час з',
        bulk_to_time: 'Час до',
        bulk_time_zone: 'Часовий пояс',
        bulk_sending_days: 'Надсилати цими днями',
        bulk_agreement: 'Угода',
        agreement: 'Угода',
        description: 'Опис',
        senderName: 'Ім\'я відправника',
        senderDomain: 'Домен відправника',
        email: 'Email',
        name: 'Ім\'я',
        lastName: 'Прізвище',
        phoneNumber: 'Телефонний номер',
        postalCode: 'Поштовий індекс',
        address: 'Адреса',
        city: 'Місто',
        country: 'Країна',
        type: 'Тип',
        value: 'Значення',
        verificationCode: 'Перевірочний код',
      },
    },
  }),
})

setLocale(lang)
