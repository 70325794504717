const SignInPage = () => import('@/application/components/pages/SignInPage.vue')
const SignUpPage = () => import('@/application/components/pages/SignUpPage.vue')
const AddPasswordPage = () => import('@/application/components/pages/AddPasswordPage.vue')
const RestorePasswordPage = () => import('@/application/components/pages/RestorePasswordPage.vue')
const DetailsPage = () => import('@/application/components/pages/DetailsPage.vue')
const DashboardWrapper = () => import('@/application/components/pages/DashboardWrapper.vue')
const InboundMessagesPage = () => import('@/application/components/pages/InboundMessagesPage.vue')
const SheduledCampaignsPage = () => import('@/application/components/pages/ScheduledCampaignsPage.vue')
const SentMessagesPage = () => import('@/application/components/pages/SentMessagesPage.vue')
const WebhookHistoryPage = () => import('@/application/components/pages/WebhookHistoryPage.vue')
const SandboxMessagesPage = () => import('@/application/components/pages/SandboxMessagesPage.vue')
const ScheduledMessagesPage = () => import('@/application/components/pages/ScheduledMessagesPage.vue')
const MessagingPage = () => import('@/application/components/pages/MessagingPage.vue')
const purchasePage = () => import('@/application/components/pages/BuyPackagesPage.vue')
const NotFoundPage = () => import('@/application/components/pages/NotFoundPage.vue')
const ForbiddenPage = () => import('@/application/components/pages/ForbiddenPage.vue')
const CurrentSenderNamesPage = () => import('@/application/components/pages/CurrentSenderNamesPage.vue')
const SenderNameDetailPage = () => import('@/application/components/pages/SenderNameDetailPage.vue')
const BillingInfoPage = () => import('@/application/components/pages/Billing/InfoPage.vue')
const InvoicesPage = () => import('@/application/components/pages/Billing/InvoicesPage.vue')
const BillingHistoryPage = () => import('@/application/components/pages/Billing/HistoryPage.vue')
const BillingBalanceHistoryPage = () => import('@/application/components/pages/Billing/BalanceHistoryPage.vue')
const ActivePurchasesPage = () => import('@/application/components/pages/Billing/ActivePurchasesPage.vue')
const Invoice = () => import('@/application/components/pages/forms/Invoice.vue')
const CommittedUseDiscountPage = () => import('@/application/components/pages/forms/CommittedUseDiscountPage.vue')
const InviteLandingPage = () => import('@/application/components/pages/InviteLandingPage.vue')
const InviteSendPage = () => import('@/application/components/pages/InviteSendPage.vue')
const InviteListPage = () => import('@/application/components/pages/InviteListPage.vue')
const IntegrationsPage = () => import('@/application/components/pages/IntegrationsPage.vue')
const LookupPage = () => import('@/application/components/pages/LookupPage.vue')
const LookupBulkHistoryPage = () => import('@/application/components/pages/LookupBulkHistoryPage.vue')
const LookupHistoryPage = () => import('@/application/components/pages/LookupHistoryPage.vue')
const ConversationApiSettingsPage = () => import('@/application/components/pages/ConversationApiSettingsPage.vue')
const LookupApiSettingsPage = () => import('@/application/components/pages/LookupApiSettingsPage.vue')
const IMessageAuthPlatformsPage = () => import('@/application/components/pages/IMessageAuthPlatformsPage')
const IMessageAuthSettingsPage = () => import('@/application/components/pages/IMessageAuthSettingsPage')
const IMessageAuthUsersPage = () => import('@/application/components/pages/IMessageAuthUsersPage')

export const routes = [
  {
    path: '/',
    component: DashboardWrapper,
    redirect: {
      name: 'dashboard',
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/imessage-auth-platforms',
        component: IMessageAuthPlatformsPage,
        name: 'imessage-auth-platforms',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/imessage-auth-users',
        component: IMessageAuthUsersPage,
        name: 'imessage-auth-users',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/imessage-auth-settings',
        component: IMessageAuthSettingsPage,
        name: 'imessage-auth-settings',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/lookup-bulk-history',
        component: LookupBulkHistoryPage,
        name: 'lookup-bulk-history',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/lookup-history',
        component: LookupHistoryPage,
        name: 'lookup-history',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/lookup',
        component: LookupPage,
        name: 'lookup',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/dashboard',
        component: DetailsPage,
        name: 'dashboard',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/details',
        component: DetailsPage,
        name: 'details',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/webhook-history',
        component: WebhookHistoryPage,
        name: 'webhook-history',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/sent-messages',
        component: SentMessagesPage,
        name: 'sent-messages',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/sandbox-messages',
        component: SandboxMessagesPage,
        name: 'sandbox-messages',
        meta: {
          requiresAuth: true,
          requiresSandbox: true,
        },
      },
      {
        path: '/scheduled-campaigns',
        component: SheduledCampaignsPage,
        name: 'scheduled-campaigns',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/scheduled-messages',
        component: ScheduledMessagesPage,
        name: 'scheduled-messages',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/inbound-messages',
        component: InboundMessagesPage,
        name: 'inbound-messages',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/messaging',
        component: MessagingPage,
        name: 'messaging',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/integrations',
        component: IntegrationsPage,
        name: 'integrations',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/purchase',
        component: purchasePage,
        name: 'purchase',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/conversation-api/settings',
        component: ConversationApiSettingsPage,
        name: 'conversation-api-settings',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/lookup-api/settings',
        component: LookupApiSettingsPage,
        name: 'lookup-api-settings',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/sender-name/show',
        component: CurrentSenderNamesPage,
        name: 'show-sender-names',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/sender-name/show/:id',
        component: SenderNameDetailPage,
        name: 'detail-sender-name',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/active-purchases',
        component: ActivePurchasesPage,
        name: 'active-purchases',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/billing',
        component: BillingInfoPage,
        name: 'billing-info',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/billing/invoices',
        component: InvoicesPage,
        name: 'invoices',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/billing/history',
        component: BillingHistoryPage,
        name: 'billing-history',
        exact: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/billing/invoices/:id',
        component: Invoice,
        name: 'invoice',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/billing/balance-history',
        component: BillingBalanceHistoryPage,
        name: 'balance-history',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/forms/committed-use-discount',
        component: CommittedUseDiscountPage,
        name: 'committed-use-discount-form',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/send-invite',
        component: InviteSendPage,
        name: 'send-invite',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/invited',
        component: InviteListPage,
        name: 'invited',
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/sign-up',
    component: SignUpPage,
    name: 'sign-up',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/landing-invite/:token',
    component: InviteLandingPage,
    name: 'landing-invite',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/sign-in',
    component: SignInPage,
    name: 'sign-in',
  },
  {
    path: '/add-password',
    component: AddPasswordPage,
    name: 'add-password',
  },
  {
    path: '/restore-password',
    component: RestorePasswordPage,
    name: 'restore-password',
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundPage,
  },
  {
    path: '/403',
    name: 'forbidden',
    component: ForbiddenPage,
  },
]
